@font-face {
	font-family: 'Poppins Regular';
	src: url('./assets/Poppins-Regular.ttf');
}
@font-face {
	font-family: 'Poppins SemiBold';
	src: url('./assets/Poppins-SemiBold.ttf');
}
@font-face {
	font-family: 'Gilroy Light';
	src: url('./assets/Gilroy-Light.otf');
}

@font-face {
	font-family: 'Gilroy ExtraBold';
	src: url('./assets/Gilroy-ExtraBold.otf');
}
* {
	/* font-family: 'IBM Plex Sans Arabic', sans-serif;
   */
	margin: 0;
	padding: 0;
}

body {
	/* overflow: hidden; */
	overflow-x: hidden;
	width: 100vw;
	height: 100vh;
}
.text {
	font-family: 'Poppins SemiBold';
}
.calculation {
	position: absolute;
	bottom: 0%;
	right: 0%;
	/* top: 0;
	right: 0; */
}
.calculation img {
	outline: none;
}
.home-page {
	width: 100%;
	height: 100%;

	padding-top: 50px;
	min-height: 100vh;
	background-image: url('./assets/bg-small.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
}
.left-image {
	width: 100%;
	height: 100%;
	/* min-height: 100vh;
	background-image: url('./assets/bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat; */
	/* position: relative; */
}

.left-image img {
	/* object-fit: contain; */
	width: 100%;
	position: absolute;
	height: 100vh;
}
.logo img {
	width: 150px;
}

.right-side {
	width: 70%;
	margin: 0 auto;
	height: 100%;
	padding: 2rem;
	position: relative;
}

.intro {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-content {
	background: #ffffff;
	border: 2px solid #ececec;
	box-shadow: 0px 25px 64px rgba(97, 97, 97, 0.07);
	border-radius: 14px;
	display: grid;
	grid-template-columns: 1fr 1fr;

	grid-gap: 0 10px;
	padding: 10px;
}
.form-content:first-child {
	margin-bottom: 8px;
}
.form-content:nth-child(2) {
	/* grid-area: '1 2 ', '3 4', '5 5'; */
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.form-item {
	display: flex;
	flex-direction: column;
}

.disabled-form label {
	color: rgba(45, 55, 72, 0.2);
}
.disabled-form input,
.disabled-form textarea,
.disabled-form select {
	background: #f5f5f5;
	color: rgba(45, 55, 72, 0.1) !important;
	font-size: 0.8rem;
}

select,
input,
textarea {
	background: #ffffff;
	/* Strokes */

	border: 1px solid #d1d1d1;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 7px;
}
textarea {
	height: 35px;
}
#button {
	background: #007aff;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px;
	width: 100%;
	border: none;
	color: #fff;
	cursor: pointer;
}

h1,
button {
	font-family: 'Poppins SemiBold';
}
label,
p,
select {
	font-family: 'Poppins Regular';
}

label,
select,
input,
option,
textarea {
	font-size: 0.8rem;
	margin-bottom: 0;
	outline: none;
}
h1 {
	font-size: 1.2rem;
}

@media (max-width: 768px) {
	.calculation {
		position: absolute;
		bottom: 0%;
		right: 40%;
	}
	.calculation img {
		outline: none;
	}
	.logo {
		display: none;
	}
	.home-page {
		background-image: url('./assets/mobileform.png');
	}

	.right-side {
		width: 90%;
		padding-top: 30vh;
	}
	.form-item {
		align-items: center;
	}

	.form-content {
		grid-template-columns: 1fr;
	}
	.form-content:nth-child(2) {
		grid-template-columns: 1fr;
		grid-template-areas: none;
	}
	input,
	select,
	textarea,
	#button {
		width: 100%;
	}

	.intro {
		text-align: start;
	}
	.left-image {
		display: none;
	}
}
@media (max-width: 768px) and (min-width: 600px) {
	.home-page {
		min-height: 130vh;
	}
	.right-side {
		padding-top: 20vh;
	}
}

.order {
	font-family: Intel-Medium;
	color: #000000;
	font-size: 16px;
	line-height: 20px;
}
ul {
	list-style: none;
}
