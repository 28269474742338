.main-page {
	display: flex;

	background-image: url('../assets/mainbg.png');
	width: 100%;
	height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.main-page .content {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.text {
	width: 80%;
}
.content .main-content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* width: 90%; */
}
.main-content p {
	font-family: 'Gilroy Light';
	font-style: normal;
	font-weight: 400;
	font-size: 36px;
	line-height: 46px;
	margin-right: 10%;
	color: #282828;
	text-align: left;
}
/* .main-content img {
	width: 100%;
	margin-left: 5%;
} */
.mobile-logos {
	display: none;
}
.logos {
	margin-left: 20%;
	width: 100%;
	margin-top: 20px;
}
.logos img:nth-child(2) {
	margin: 0 5%;
}
.main-content p span {
	font-weight: 600;
}

.buttons {
	position: absolute;
	top: 50%;
	display: flex;
	flex-direction: column;
	right: 5%;
}
.buttons a button {
	background: #ebeef3;
	box-shadow: -27px -27px 72px #0a3da1, 27px 27px 72px rgba(0, 0, 0, 0.2),
		inset 3.6px 3.6px 18px #ffffff;
	border: none;
	border-radius: 54px;

	font-family: 'Poppins SemiBold';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 33px;
	text-align: center;

	padding: 16px 24px;
	width: 100%;
	color: #083284;
	margin: 5% 0;
}

.svg-logo {
	display: none;
}

@media only screen and (min-width: 1920px) {
	.main-content p {
		font-size: 47px;
		line-height: 66px;
		width: 80%;
	}
	.buttons a button {
		padding: 35px;
		font-size: 25px;
	}
	.logos {
		display: inline;
		/* margin-left: 10%; */
	}
}
@media only screen and (min-width: 1600px) and (max-width: 1919px) {
	.main-content p {
		margin-right: 18%;
		font-size: 40px;
	}
}
@media only screen and (min-width: 1441px) and (max-width: 1599px) {
	.main-content p {
		margin-right: 18%;
		font-size: 36px;
	}
}
@media only screen and (min-width: 2045px) {
}

@media only screen and (max-width: 1024px) and (min-width: 900px) {
	.main-page {
		background-position: right;
	}
	.buttons a button {
		padding: 10px;
		width: 100%;
		color: #083284;
		margin: 5% 0;

		font-size: 18px;
	}

	.main-content p {
		font-family: 'Gilroy Light';
		font-style: normal;
		font-weight: 400;
		width: 60%;
		font-size: 20px;
		line-height: 26px;
		margin-left: 10%;
		color: #282828;
	}
	.main-content .logos {
		width: 80%;
	}
	.logos {
		margin-left: 0;
	}
	.logos img {
		width: 30%;
	}
}

@media only screen and (max-width: 900px) {
	.content {
		display: none !important;
	}
	.main-page {
		background-image: url('../assets/mobilebg.png');
		height: 100vh;
	}
	.mobile-logos {
		/* position: absolute;
		top: 10%;
		left: 15%; */
		display: flex;
		margin: 0 auto;
		margin-top: 50px;
	}
	/* .mobile-logos img:first-child {
		margin-right: 15%;
	} */
	/* .mobile-logos img {
		width: 120px;
		height: 100px;
	} */

	.buttons {
		position: absolute;
		/* bottom: 5%; */
		width: 80%;
		top: 76%;
		right: 10%;
	}
	.buttons a button {
		margin-top: 1vh !important;
		margin: 0 auto;
		width: 100%;
		padding: 15px 10px;
		border-radius: 60px;

		font-size: 20px;

		font-family: 'Poppins SemiBold';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		text-align: center;

		color: #083284;

		box-shadow: -2px -2px 80px #0a3da1, 30px 30px 80px rgba(0, 0, 0, 0.2),
			inset 4px 4px 20px #ffffff;
	}
}
@media only screen and (max-width: 350px) {
	#img-1 {
		width: 200px;
	}
	#img-2 {
		width: 100px;
	}
	#img-3 {
		width: 150px;
	}
}
