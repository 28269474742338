.photo-field {
	width: 100%;
	height: 100px;
	margin-bottom: 10px;
	border: 1px solid rgba(0, 0, 0, 0.5);
	position: relative;
}
.photo-field::after {
	content: '+';
	position: absolute;
	top: 20%;
	left: 50%;
	font-size: 3rem;
	transform: translateX(-50%);
}
.photo-result {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100px;

	z-index: 100;
}
