.nav-list {
	display: flex;
	list-style: none;
	align-items: center;
	gap: 1.5rem;
	margin: 0;
}
.header {
	top: 0;
	margin-left: 60px;
	z-index: 100;
}

.header a {
	text-decoration: none;
	color: #2d2d2d;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	font-size: 1rem;
}
.mobile-menu {
	display: none;
}

@media only screen and (max-width: 880px) {
	.mobile-menu {
		display: block;
	}

	.header {
		margin-left: 5px;
	}
	.nav {
		justify-content: space-between;
	}
	.nav-list {
		display: none;
	}

	.mobile-tab {
		list-style: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: #fff;

		transition: all 0.2s linear;
		transform: translateX(-100%);

		opacity: 0;
	}
	.mobile-tab-active {
		transform: translateX(0);
		opacity: 1;
	}
}
