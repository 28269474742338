.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding: 100px; /* Location of the box */
	left: 0;
	top: 0;
	min-height: 80vh;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: scroll; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-header {
	padding: 2px 16px;

	color: white;
}

/* Modal Content */
.modal-content {
	position: relative;
	background-color: #fefefe;
	margin: auto;
	width: 100%;
	height: 100%;
	padding: 0;
	height: 70vh;

	border: 1px solid #888;
	width: 60%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-animation-name: animatetop;
	-webkit-animation-duration: 0.4s;
	animation-name: animatetop;
	animation-duration: 0.4s;
}

.succes-button {
	width: 100%;
}
/* Add Animation */
@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}

@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
/* The Close Button */
.close {
	color: white;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.modal-body {
	/* padding: 2px 16px; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.modal-body p {
	font-family: 'Poppins Regular';
	margin-top: 3vh 2vw;
}

#modal-bg-img {
	position: absolute;
	top: 0;
	left: -5%;
	width: 100%;
	height: 100%;
}
.t-link {
	text-decoration: none;
	display: block;
	font-size: 1rem;
}
@media only screen and (max-width: 880px) {
	.modal-content {
		width: 80vw;
	}
	.modal {
		padding: 100px 10px;
	}
}
